* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  overflow-x: hidden;
  width: 100%;
}

:root {
  --color-131921: #131921;
  --color-3b4149: #3b4149;
  --color-febd69: #febd69;
  --color-232f3e: #232f3e;
  --color-bf4800: #bf4800;
  --color-f5f5f7: #f5f5f7;
  --color-ededed: #ededed;
  --color-777777: #777777;
  --color-1c1c1b: #1c1c1b;
}

.header-upper {
  background: linear-gradient(to bottom, #ff1b00c9, #ff8800) !important;
}

.navbar.navbar-expand-lg {
  background: #ff8800;
}

.banner-data {
  margin-left: 8%;
  margin-top: -50%;
  z-index: 1;
}

.card-wrapper {
  box-shadow: 0 0 10px #0000001a;
  padding: 15px;
}

.awssld__content {
  background-color: #ffffff;
}

body {
  font-family: "Rubik", sans-serif;
}

h4 {
  font-size: 1.4rem;
}

p {
  font-size: 1rem;
}

a {
  text-decoration: none;
  display: inline-block;
}

.form-control:focus {
  box-shadow: none;
  border-color: inherit;
}

.gap-10 {
  gap: 10px;
}

.gap-15 {
  gap: 15px;
}

.gap-30 {
  gap: 30px;
}

.button {
  background-color: var(--color-232f3e);
  color: white;
  font-size: 14px;
  padding: 13px 33px;
  border-radius: 25px;
}

.button:hover {
  background-color: var(--color-febd69);
  color: var(--color-131921);
}

/* Header Part */

header {
  width: 100%;
}

.header-top-strip {
  border: 1px solid var(--color-3b4149);
  background-color: var(--color-131921);
}

.header-bottom {
  background-color: var(--color-232f3e);
}

.header-upper .input-group-text {
  background-color: var(--color-febd09);
}

.header-link {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: rgb(0, 0, 0);
  text-align: center;
}

.electronicList {
  font-size: 12px;
}

.electronicList li a {
  color: black;
  /* font-weight: 500; */
  line-height: 1.2rem;
  word-spacing: 0.2rem;
}

.header-upper.header-upper-links img {
  width: 20px;
  height: 10px;
}

.header-bottom .menu-links a,
.header-bottom .dropdown button {
  color: rgb(255, 255, 255);
  font-size: 14px;
  /* line-height: 18px; */
  font-weight: 400;
  letter-spacing: 0.3;
  text-transform: uppercase;
}

.header-bottom .dropdown button:focus {
  box-shadow: none;
}

.header-bottom .dropdown-menu {
  background-color: var(--color-131921);
  width: 100%;
  transform: translate3d(0px, 57px, 0px) !important;
}

.header-bottom .dropdown-item {
  /* padding: 20px;
 margin: 3px; */
  border-bottom: 1px solid var(--color-3b4149);
}

.header-bottom .dropdown-item:hover {
  background-color: transparent;
  color: var(--color-febd09) !important;
}

/* Header Part End Here */

/* Footer part */

footer {
  /* background-color: #111827 !important; */
  background: #002a32 !important;
}

footer:not(:first-child) {
  border-top: 1px solid #ffffff;
}

footer .input-group-text {
  background-color: var(--color-232f3e);
  color: white;
  border: 8px 17px;
}

/* footer parts end here */

/* Home Page  */

.main-banner img {
  border-radius: 0.6rem !important;
}

.main-banner-content {
  top: 15%;
  left: 6%;
}

.main-banner-content h4 {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.3em;
  color: var(--color-bf4800);
  margin: 0 0 12px;
  letter-spacing: 0.3px;
  text-transform: uppercase;
  word-wrap: break-word;
}

.main-banner-content h5 {
  font-size: 52px;
  line-height: 64px;
  font-weight: 500;
  margin: 0 0 12px;
  letter-spacing: -2px;
  text-transform: none;
}

.main-banner-content p {
  font-size: 18px;
  line-height: 20px;
  color: var(--color-131921);
  letter-spacing: 0.4px;
}

.small-banner img {
  border-radius: 0.6rem !important;
  width: 100%;
  transition: transform 0.2s ease-in-out;
  /* add transition effect */
}

.small-banner img:hover {
  border-radius: 0.6rem !important;

  transform: scale(1.1);
  /* add zoom effect */
}

.hovering img {
  transition: transform 0.2s ease-in-out;
  /* add transition effect */
}

.hovering img:hover {
  transform: scale(1.1);
  /* add zoom effect */
}

.small-banner-content {
  top: 20%;
  left: 10%;
  width: 50%;
}

.small-banner-content h4 {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: var(--color-bf4800);
  margin: 0 0 12px;
  letter-spacing: 0.3px;
  text-transform: uppercase;
}

.small-banner-content h5 {
  font-size: 22px;
  line-height: 34px;
  font-weight: 500;
  margin: 0 0 12px;
  letter-spacing: 1px;
  text-transform: none;
}

.small-banner-content p {
  font-size: 14px;
  line-height: 22px;
  color: var(--color-131921);
  letter-spacing: 0.4px;
}

/* Home Wrapper 2 */

.home-wrapper-2 {
  background-color: var(--color-f5f5f7);
  border-radius: 16px;
}

/* .home-wrapper-2 img{
  width: 50%;
  height: 20%;
} */
/* categories sections */
.categories {
  background-color: white;
  box-shadow: 0 0 10px #0000001a;
  padding: 15px;
}

.categories > div {
  width: auto;
}

/* categories section end here */

/* Blog Part */

.blog-content img {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.cardImg {
  transition: transform 0.2s ease-in-out;
}

.cardImg:hover {
  transform: scale(1.1);
}

.blog-content .data {
  font-size: 13px;
  font-weight: 400;
  line-height: 24px;
  color: var(--color-777777);
  padding: 0;
  letter-spacing: 0;
  text-transform: uppercase;
}

.blog-content h5 {
  font-size: 18px;
  color: var(--color-131921);
}

.blog-content p.desc {
  font-size: 13px;
  line-height: 22px;
  color: var(--color-777777);
}

.single-blog-card a {
  font-size: 18px;
  color: var(--color-777777);
  margin-bottom: 20px;
}

.single-blog-card h3 {
  font-size: 24px;
  font-weight: 600;
  color: var(--color-1c1c1b);
}

.single-blog-card p {
  color: var(--color-777777);
  font-size: 14px;
}

/* Blog Part Ens Here */

/* Product Card */

.product-card {
  padding: 5px;
}

.product-card .card {
  overflow: hidden;
}

/* .product-card .card:hover .action-bar{
  right: 70px;
} */

.product-card .heart-icon {
  top: 18%;
  margin-left: 2px;
}

.product-card h6 {
  color: var(--color-bf4800);
  font-size: 13px;
}

.product-card h5 {
  font-size: 15px;
  color: var(--color-1c1c1b);
}

.product-card .price {
  font-size: 16px;
  font-weight: 500;
  color: var(--color-1c1c1b);
  margin-bottom: 0;
}

@media only screen and (max-width: 767px) {
  .product-card .card {
    overflow: hidden;
  }

  .product-card {
    width: 100%;
    margin-bottom: 20px;
  }

  @media screen and (min-width: 576px) {
    .product-card {
      width: 50%;
    }
  }

  @media screen and (min-width: 768px) {
    .product-card {
      width: 50%;
    }
  }

  @media screen and (min-width: 992px) {
    .product-card {
      width: 50%;
    }
  }

  .gr-12 .action-bar {
    top: 15%;
  }

  /* .action-bar {
    top: 8%;
    right: 98px;
    transition: .3s;
  } */

  /* 
  .action-bar {
   top: 20%;
    right: 235px;
    display: none;
  } */

  .product-card .heart-icon {
    top: 18%;
    margin-top: 2px;
  }
}

.product-image:hover {
  transition: transform 0.7s ease-in-out;
}

.fs-1:hover {
  transform: rotate(360deg);
  transition: all 2s ease-in-out 0s;
}

.fs-2:hover {
  transform: rotate(360deg);
  transition: all 2s ease-in-out 0s;
}

/* Product Card end here */

/* Special Products */
.special-product-card span {
  top: 5%;
  left: 20px;
}

/* .special-product-card img{
  aspect-ratio: 1/1.7;
} */

.special-product-card .heart-icon {
  top: 5%;
  right: 20px;
}

.special-product-card h6 {
  color: var(--color-bf4800);
  font-size: 13px;
}

.special-product-card h5 {
  font-size: 15px;
  color: var(--color-1c1c1b);
}

.special-product-card .price {
  font-size: 16px;
  font-weight: 500;
  color: var(--color-1c1c1b);
  margin-bottom: 0;
}

.special-product-card .stars {
  margin-left: -20px;
}

/* Special Products end here */

/* famous product */

.famous-product {
  border: none;
}

.slick-prev,
.slick-next {
  background-color: transparent !important;
}

.famous-product h6 {
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 7px;
}

.famous-product h5 {
  font-size: 25px;
  font-weight: 500;
  line-height: 38px;
}

.famous-product p {
  font-size: 16px;
  font-weight: 100;
  line-height: 24px;
}

@media (max-width: 576px) {
  .famous-product h5 {
    font-size: 20px;
    line-height: 30px;
  }

  .card-img {
    width: 100%;
    height: auto;
  }

  @media (min-width: 576px) {
    .card-img {
      max-width: 50%;
    }
  }

  .famous-product p {
    font-size: 14px;
    line-height: 20px;
  }
}

/* famous product end here*/

/* Our Store */

.filter-card {
  background-color: white;
  border-radius: 10px;
  padding: 10px 15px 25px;
}

.filter-title {
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
  color: var(--color-1c1c1b);
  margin-bottom: 20px;
}

.filter-card ul,
.filter-card .form-check-label {
  list-style-type: none;
  font-size: 13px;
  line-height: 30px;
  color: var(--color-777777);
  cursor: pointer;
}

.filter-card .form-check-input:focus {
  box-shadow: none;
  background-color: rgba(0, 0, 0, 0.25);
}

.filter-card .form-check-input:checked {
  background-color: var(--color-febd09);
  border: none;
}

.sub-title {
  font-size: 14px;
  font-weight: 600;
  color: var(--color-1c1c1b);
  margin-bottom: 15px;
  margin-top: 20px;
}

.colors {
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.colors li {
  width: 20px;
  height: 20px;
  background-color: red;
  border-radius: 50%;
}

.random-products h5 {
  font-size: 14px;
  margin-bottom: 8px;
}

.random-products img {
  aspect-ratio: 1/1.7;
}

.filter-sort-grid {
  padding: 10px;
  background-color: #fff;
  border-radius: 10px;
}

.grid img {
  width: 35px;
  height: 35px;
  background-color: #f2f2f2;
  padding: 3px;
  border-radius: 8px;
}

.gr-12 {
  width: 100%;
}

.gr-6 {
  width: 49%;
}

/* .gr-4 {
  width: 32%;
} */
.gr-3 {
  width: 24%;
}

.gr-6 .product-image {
  width: 100%;
}

.gr-6 .product-image img {
  margin: auto;
  display: block;
}

.gr-12 .product-card .card {
  width: 270px;
}

/* .gr-12 .product-card .product-image {
  width: 270px;
} */
.gr-12 .product-card .product-image {
  height: auto;
}

/* Our store ends here */

/* Contact Page */
.contact-inner-wrapper {
  padding: 30px 20px;
  border-radius: 15px;
  background-color: white;
  gap: 15px;
  color: var(--color-f5f5f7);
}

/* Contact Page ends */

/* Compare Product Page */

.compare-product-card .heart-icon {
  top: 2%;
  right: 20px;
  color: black;
}

.compare-product-card h6 {
  color: black;
  font-size: 13px;
}

.compare-product-card .data-list {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.compare-product-card .price {
  font-size: 16px;
  font-weight: 500;
  color: var(--color-1c1c1b);
  margin-bottom: 0;
}

.compare-product-card h5 {
  font-size: 16px;
  font-weight: 500;
  color: var(--color-1c1c1b);
}

/* Compare Product Page end here */

/* wishlist product page */

.wishlist-wrapper .heart-icon {
  top: 2%;
  right: 20px;
  color: black;
}

.wishlist-wrapper .price {
  font-size: 16px;
  font-weight: 500;
  color: var(--color-1c1c1b);
  margin-bottom: 0;
}

.wishlist-wrapper h6 {
  font-size: 16px;
  font-weight: 500;
  color: var(--color-1c1c1b);
}

/* wishlist product page end here */

/*  auth card */

.Forget-password-wrapper .price {
  font-size: 16px;

  color: var(--color-777777);
  margin-bottom: 0;
}

.signup {
  background-color: var(--color-febd69);
  color: var(--color-131921);
  border: none;
}

.signup:hover {
  background-color: var(--color-131921);
  color: white;
}

/*  auth card  ends*/

/* Policy Pages */

.policy {
  background-color: white;
  padding: 30px;
}

/* Policy Pages  ends */

/* Main Product Page */

.review-logo {
  height: 70px;
  width: 70px;
  /* margin-left: 30px; */
  font-size: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: rebeccapurple;
}

.review-logo p {
  font-size: 30px;
  margin-bottom: 0;
  text-align: center;
}

.review-font h5 {
  /* margin-left: 30px; */
  margin-bottom: 0;
}

/*Main product detail*/

img {
  max-width: 100%;
}

.preview {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

@media screen and (max-width: 996px) {
  .preview {
    margin-bottom: 20px;
  }
}

/* .preview-pic {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
      -ms-flex-positive: 1;
          flex-grow: 1; } */

.preview-thumbnail.nav-tabs {
  border: none;
  margin-top: 15px;
}

.preview-thumbnail.nav-tabs li {
  width: 18%;
  margin-right: 2.5%;
}

.preview-thumbnail.nav-tabs li img {
  max-width: 100%;
  display: block;
}

.preview-thumbnail.nav-tabs li a {
  padding: 0;
  margin: 0;
}

.preview-thumbnail.nav-tabs li:last-of-type {
  margin-right: 0;
}

.tab-content {
  overflow: hidden;
}

.tab-content img {
  width: 100%;
  -webkit-animation-name: opacity;
  animation-name: opacity;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
}

@media screen and (min-width: 997px) {
  .wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
}

.details {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.colors {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.product-title,
.price,
.sizes,
.colors {
  text-transform: UPPERCASE;
  font-weight: 700;
}

.checked,
.price span {
  color: #ff9f1a;
}

.product-title {
  margin-top: 0;
}

.size {
  margin-right: 10px;
  border: 1px solid black;
  padding: 5px;
  border-radius: 2%;
}

.size:first-of-type {
  margin-left: 40px;
}

.color {
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
  height: 2em;
  width: 2em;
  border-radius: 2px;
}

.color:first-of-type {
  margin-left: 20px;
}

.compare-wishlist a {
  text-decoration: none;
  color: dark;
}

.orange {
  background: #ff9f1a;
}

.green {
  background: #85ad00;
}

.blue {
  background: #0076ad;
}

.tooltip-inner {
  padding: 1.3em;
}

@-webkit-keyframes opacity {
  0% {
    opacity: 0;
    -webkit-transform: scale(3);
    transform: scale(3);
  }

  100% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes opacity {
  0% {
    opacity: 0;
    -webkit-transform: scale(3);
    transform: scale(3);
  }

  100% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

/* sourceMappingURL=style.css.map */

/* Main Product Page */

/* Cart Page */
.cart-header,
.cart-data {
  border-bottom: 1px solid #eaeaea;
}

.cart-header h4 {
  font-size: 13px;
  color: var(--color-777777);
}

.cart-col-1 {
  width: 40%;
}

.cart-col-1 p {
  color: var(--color-777777);
  font-size: 14px;
}

.cart-col-2 {
  width: 10%;
}

.cart-col-3 {
  width: 15%;
}

.cart-col-4 {
  width: 15%;
}

.cart-data h5.price {
  font-size: 14px;
  color: var(--color-1c1c1b);
}

/* Cart Page Ends here */

/* Checkout */

/* Checkout End Here */

/* Shippping Page */

.total {
  font-size: 14px;
  color: var(--color-1c1c1b);
}

.total-price {
  font-size: 16px;
  color: var(--color-777777);
}

/* Shipping page ends here */

/*Navbar Category*/
.img-container {
  width: 64px;
  height: 64px;
  position: relative;
  margin: 0 auto;
}

.img-container a {
  text-decoration: none;
  color: #000;
}

.img-container > img {
  position: absolute;
  align-self: center;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  margin: auto;
  opacity: 1;
  max-width: 100%;
  max-height: 100%;
}

/*Navbar Category end here*/
.product-list {
  display: flex;
  flex-wrap: wrap;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
  padding: 0 15px;
}

@media (max-width: 576px) {
  .col-3 {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

.product-card {
  flex-basis: calc(25% - 10px);
  margin-bottom: 10px;
}

.col-sm-7 {
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  font-size: 14px;
  color: #212121;
  line-height: 1.4;
  font-family: Roboto, Arial, sans-serif;
  letter-spacing: 0;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.col-sm-7 .button {
  margin-right: 30px;
  background-color: #ff9f00;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  cursor: pointer;
  display: inline-block;
}

.col-sm-7 .button:hover {
  background-color: #27ae60;
}

.disable {
  opacity: 0.5;
  /* reduce the opacity to 50% */
  pointer-events: none;
  /* disable pointer events */
}

/* 

.circle{
  width:200px;
  height:200px;
  border: 2rem solid linear-gradient(to right, #f12711, #f5af19);
  border-radius:50%;
  position:relative;
  animation: rotate 0.8s linear infinite;
}

.circle::before{
  content:"";
  height:180px;
  width:180px;
  border-color: 2rem solid red;
  border-radius:50%;
  background:transparent;
  position:absolute;
  top:calc(0% + 10px);
  left:calc(0% + 10px); 
} 
@keyframes rotate {
  0%{
    transform:rotate(0deg);
  }
  50%{
    trasnform:rotate(180deg);
  }
  100%{
    transform:rotate(360deg);
  }
} */

/* 
.circle {
  background-image:linear-gradient(red,green);
  padding:1px;
  width:300px;
  height:300px;
  border-style: solid;
  border-color:transparent;
   border-radius: 50%;
  border-width:1px;
 animation:rot 1s linear infinite; 
  padding:1px;
}
.circle > div {
  background:white;
  height: 100px;
  width: 100px;
  border-style: solid;
  border-color:transparent;
   border-radius: 50%;
  border-width:1px;

  
}

.circle img {
  
  animation:none;
  
}
img{
  position:absolute;
  z-index:10;
  top:130px;  
  left:35px;
}
@keyframes rot {
   0%
  {
    transform:rotate(0deg)
  }
   25%
  {
    transform:rotate(90deg)
  }
  50%
  {
    transform:rotate(180deg)
  }
   75%
  {
    transform:rotate(270deg)
  }
  100% {
    transform:rotate(360deg)
  }
}
 */

.img-container {
  position: relative;
  display: inline-block;
  overflow: hidden;
  border-radius: 1800px;
  cursor: pointer;
}

.img-container::before {
  content: "";
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  z-index: -1;
  background: linear-gradient(
    45deg,
    #ff9a9e,
    #fad0c4,
    #fad0c4,
    #f6d365,
    #fda085,
    #f6d365,
    #f6d365
  );
  border-radius: 1800px;
  animation: animateBorder 2s linear infinite;
}

@keyframes animateBorder {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* todo naviagtion css */
/* .navC{
  filter: blur(2px);
  position: relative;
} */

#navBar {
  background-color: #b9a7a730;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(5px);
  display: flex;
  position: fixed;
  height: 80px;
  align-items: center;
  width: 100%;
  top: 0;
  z-index: 999;
}

.notiSpan {
  cursor: pointer;
  font-size: 17px;
  position: relative;
  padding: 0px 5px;
  border-radius: 100px;
}

.compareSpan {
  top: 2px;
  left: -25px;
}

.whishlistSpan {
  background: #fff;
  top: -6px;
  left: -9px;
}

#nav-sidebar {
  background: #b9a7a730;
  position: fixed;
  left: -120vw;
  top: 0;
  height: 100vh;
  backdrop-filter: blur(10px);
  z-index: 9999;
  transition: all 0.3s ease-in-out;
}

.menubtn {
  width: 100%;
  border: 2px solid transparent;
  margin: 5px 0px;
  padding: 5px 1px 5px 18px;
  color: #131921;
  font-size: 20px;
  font-weight: 500;
  cursor: pointer !important;
}
.menubtn:hover {
  color: rgb(16, 93, 209);
}

.menubtnActive {
  width: 100%;
  border: 2px solid transparent;
  margin: 5px 0px;
  padding: 5px 1px 5px 18px;
  color: #ffffff;
  background: #002a32;
  font-size: 20px;
  font-weight: 500;
  cursor: pointer !important;
}

.logo-Sidebar {
  height: 80px;
  cursor: pointer;
}

/* product card */

@media (min-width: 992px) {
  .productCard {
    flex: 0 0 auto;
    width: 24% !important;
    cursor: pointer;
  }
}
@media only screen and (max-width: 720px) and (min-width: 400px) {
  .productCard {
    flex: 0 0 auto;
    width: 48% !important;
    cursor: pointer;
  }
}

.about-page h6 {
  color: #5f5c5c !important;
}
