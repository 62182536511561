.loginwrapper {
    height: 100%;
    background: linear-gradient(135deg, #ffffff, #b3b0b0);
    backdrop-filter: blur(20px);
  }
  
  .login.container {
    display: flex;
    width: 520px;
    height: 500px;
    max-width: 99%;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
    background-color: #ffffff25;
    border-radius: 15px;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.03);
    border: 0.1px solid rgba(128, 128, 128, 0.178);
  }
  .ik{
    color: black;
  }
  .ik:hover{
    color: black !important;
  }
  .signup.container {
    display: flex;
    width: 60%;
    height: 700px;
  }
  .forgot-get {
    display: flex;
    width: 370px;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: flex-start;
    overflow: hidden;
    background-color: #ffffff25;
    border-radius: 15px;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.03);
    border: 0.1px solid rgba(128, 128, 128, 0.178);
  }
  
  .login.left {
    width: 70%;
    height: 100%;
  }
  
  .login.form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    width: 100%;
    left: 0;
    backdrop-filter: blur(20px);
    position: relative;
  }
  .forgot-get .login.form {
    height: 50%;
  }
  .login .login.form::before {
    position: absolute;
    content: "";
    width: 40%;
    height: 40%;
    right: 1%;
    z-index: -1;
    background: radial-gradient(
      circle,
      rgb(194, 13, 170) 20%,
      rgb(26, 186, 235) 60%,
  
      rgb(26, 186, 235) 100%
    );
    filter: blur(70px);
    border-radius: 50%;
  }
  
  .login.right {
    width: 30%;
    height: 100%;
  }
  @media only screen and (min-width: 100px) and (max-width: 900px) {
    .login.right {
      width: 0% !important;
      height: 100%;
    }
    .login.left {
      width: 100%;
      height: 100%;
    }
    .signup.container {
      display: flex;
      width: 100%;
      height: 700px;
    }
  }
  
  .login.img {
    width: 100%;
    height: 100%;
  }
  
  .login.container::after {
    position: absolute;
    content: "";
    width: 80%;
    height: 80%;
    right: -40%;
    background: rgb(157, 173, 203);
    background: radial-gradient(
      circle,
      rgba(157, 173, 203, 1) 61%,
      rgba(99, 122, 159, 1) 100%
    );
    border-radius: 50%;
    z-index: -1;
  }
  
  .login.input,
  .loginbtn {
    background: rgba(253, 253, 253, 0);
    outline: none;
    border: 1px solid rgba(255, 0, 0, 0);
    border-radius: 0.5rem;
    padding: 10px;
    margin: 16px auto;
    width: 80%;
    display: block;
    color: #425981;
    font-weight: 500;
    font-size: 1.1em;
  }
  
  .login.input-block {
    position: relative;
  }
  
  .login label {
    position: absolute;
    left: 15%;
    top: 30%;
    pointer-events: none;
    color: gray;
  }
  
  .login.forgot {
    display: block;
    margin: 5px 0 10px 0;
    margin-left: 51px;
    color: #5e7eb6;
    font-size: 0.9em;
  }
  
  .login.input:focus + label,
  .login.input:valid + label {
    transform: translateY(-120%) scale(0.9);
    transition: all 0.4s;
  }
  
  .loginbtn {
    background-color: #5e7eb6;
    color: white;
    font-size: medium;
    box-shadow: 2px 4px 8px rgba(70, 70, 70, 0.178);
  }
  
  .login link {
    margin-left: 20px;
    color: #5e7eb6;
  }
  
  .login.input {
    box-shadow: inset 4px 4px 4px rgba(165, 163, 163, 0.315),
      4px 4px 4px rgba(218, 218, 218, 0.13);
  }
  